// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JC8dK6V5sHfe7Pb_jEgg .pieLabel{background-color:#fff;font-size:12px;color:var(--ps-grey-primary);padding:1px 3px;border-radius:4px;pointer-events:none}`, "",{"version":3,"sources":["webpack://./overrides/pages/tagExplorer/components/TotalSamplesChart/PieChart/styles.module.scss"],"names":[],"mappings":"AAEI,gCACE,qBAAA,CACA,cAAA,CACA,4BAAA,CACA,eAAA,CACA,iBAAA,CACA,mBAAA","sourcesContent":[".wrapper {\n  :global {\n    .pieLabel {\n      background-color: white;\n      font-size: 12px;\n      color: var(--ps-grey-primary);\n      padding: 1px 3px;\n      border-radius: 4px;\n      pointer-events: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `JC8dK6V5sHfe7Pb_jEgg`
};
export default ___CSS_LOADER_EXPORT___;
